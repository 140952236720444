import React, { createContext, useContext, useRef, useCallback, useState, useEffect } from 'react';
import { domToPng } from 'modern-screenshot';
import { Dialog, DialogActions, DialogContent, IconButton, makeStyles, CircularProgress } from '@material-ui/core';
import { GetApp as DownloadIcon, Share as ShareIcon, FileCopy as CopyIcon, Close as CloseIcon } from '@material-ui/icons';
import overlay from 'assets/img/logo-overlay.png';
import overlayExtend from 'assets/img/logo-overlay-extend-left.png';

const ScreenshotContext = createContext();

export const useScreenshot = () => {
  const context = useContext(ScreenshotContext);
  if (!context) {
    throw new Error('useScreenshot must be used within a ScreenshotProvider');
  }
  return context;
};

const useStyles = makeStyles(() => ({
    rotating: {
      animation: '$rotate 1s linear infinite',
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    shareButtons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    textField: {
      width: '100%',
      marginTop: '10px',
      borderRadius: '4px',
    },
    dialogContent: {
      textAlign: 'center',
      backgroundColor: '#222',
      color: '#fff',
    },
    dialogActions: {
      justifyContent: 'space-between',
      padding: '8px 24px',
      backgroundColor: '#333',
    },
    screenshotImage: {
      width: '100%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    formControl: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    combinedIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    iconSpacer: {
      marginLeft: '5px',
    },
    hideScrollbars: {
      '& *': {
        scrollbarWidth: 'none', // Firefox
      },
      '& *::-webkit-scrollbar': {
        display: 'none', // Chrome, Safari, Opera
      },
    },
  }));
  

// Polyfill for ClipboardItem if not defined
if (typeof window.ClipboardItem === 'undefined') {
  window.ClipboardItem = class ClipboardItem {
    constructor(data) {
      this.data = data;
    }

    async getType(type) {
      return this.data[type];
    }
  };
}

// Function to convert Blob to base64
async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// Clipboard API copy function
async function clipboardAPICopy(blob) {
  const item = new window.ClipboardItem({ 'image/png': blob });
  await navigator.clipboard.write([item]);
}

// ExecCommand copy function
async function execCommandCopy(blob) {
  const base64data = await blobToBase64(blob);
  const image = document.createElement("img");
  image.style.display = "none";
  image.src = "data:image/png;base64," + base64data;
  document.body.appendChild(image);

  const selection = window.getSelection();
  selection.removeAllRanges();
  const range = document.createRange();
  range.selectNode(image);
  selection.addRange(range);

  document.execCommand("copy");
  selection.removeAllRanges();

  document.body.removeChild(image);

  console.log("Image copied using execCommand API");
}

// Copy Blob to clipboard function
async function copyBlobToClipboard(blob) {
  try {
    await clipboardAPICopy(blob);
    console.log("Image copied using the Clipboard API!");
  } catch (error) {
    console.log("Received", error.name, "when accessing the Clipboard API. Falling back to execCommand.");
    await execCommandCopy(blob);
    console.log("Image copied using the execCommand API!");
  }
}

export const ScreenshotProvider = ({ children }) => {
  const screenshotClasses = useStyles();
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState('');
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const [shareMessage, setShareMessage] = useState('Check out this screenshot!');
  const [webShareSupported, setWebShareSupported] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    setWebShareSupported(!!navigator.share);
    setIsFirefox(typeof InstallTrigger !== 'undefined');
  }, []);

  const handleScreenshot = useCallback(() => {
    if (componentRef.current) {
      setScreenshotLoading(true);
  
      componentRef.current.classList.add(screenshotClasses.hideScrollbars);
  
      setTimeout(() => {
        domToPng(componentRef.current, { width: window.innerWidth, height: window.innerHeight, backgroundColor: '#222222', quality: 100 })
          .then(async dataUrl => {
            componentRef.current.classList.remove(screenshotClasses.hideScrollbars);
  
            const image = new Image();
            image.src = dataUrl;
            await new Promise(resolve => {
              image.onload = resolve;
            });
  
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const borderSize = 10; // Fixed border size
  
            // Crop parameters (same as before)
            let cropTop = 64;
            let cropLeft = 0;
            let cropRight = 0;
            let cropBottom = 0;
  
            const isHorizontal = window.innerWidth > window.innerHeight;
            if (window.location.pathname === '/' && isHorizontal) {
              cropLeft = 17;
              cropRight = 17;
              cropBottom = 17;
            }
  
            const croppedWidth = image.width - cropLeft - cropRight;
            const croppedHeight = image.height - cropTop - cropBottom;
  
            // Set canvas size to include border
            canvas.width = croppedWidth + borderSize * 2;
            canvas.height = croppedHeight + borderSize * 2;
  
            // Draw angular border with gradient
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
            gradient.addColorStop(0, '#2a2a2a');
            gradient.addColorStop(1, '#1a1a1a');
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
  
            // Draw yellow accent (top-left corner)
            ctx.fillStyle = '#ffd700';
            ctx.fillRect(0, 0, borderSize, borderSize);
  
            // Draw cropped image
            ctx.drawImage(image, cropLeft, cropTop, croppedWidth, croppedHeight, borderSize, borderSize, croppedWidth, croppedHeight);
  
            // Add subtle shadow
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.strokeStyle = '#333333';
            ctx.lineWidth = 2;
            ctx.strokeRect(borderSize, borderSize, croppedWidth, croppedHeight);
  
            // Reset shadow
            ctx.shadowColor = 'transparent';
  
            // Add overlay images (same as before)
            const overlayImage = new Image();
            const overlayExtendImage = new Image();
            overlayImage.src = overlay;
            overlayExtendImage.src = overlayExtend;
  
            await Promise.all([
              new Promise(resolve => {
                overlayImage.onload = resolve;
              }),
              new Promise(resolve => {
                overlayExtendImage.onload = resolve;
              })
            ]);
  
            const overlayHeight = Math.min(canvas.height * 0.05, overlayImage.height);
            const overlayWidth = (overlayHeight / overlayImage.height) * overlayImage.width;
            const overlayExtendWidth = canvas.width - overlayWidth;
            
            ctx.drawImage(overlayExtendImage, 0, canvas.height - overlayHeight, overlayExtendWidth, overlayHeight);
            ctx.drawImage(overlayImage, overlayExtendWidth, canvas.height - overlayHeight, overlayWidth, overlayHeight);
  
            const finalDataUrl = canvas.toDataURL('image/png');
            setScreenshotUrl(finalDataUrl);
            setOpen(true);
          })
          .finally(() => {
            setScreenshotLoading(false);
          });
      }, 50);
    }
  }, [screenshotClasses.hideScrollbars]);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = `gunbot-screenshot-${new Date().toISOString().replace(/[:.]/g, '-')}.png`;
    link.href = screenshotUrl;
    link.click();
  };

  const getShareText = () => {
    return `${shareMessage} https://www.gunbot.com/`;
  };

  const shareUsingWebShareAPI = async () => {
    if (navigator.share) {
      try {
        const response = await fetch(screenshotUrl);
        const blob = await response.blob();
        const filesArray = [new File([blob], `gunbot-screenshot-${new Date().toISOString().replace(/[:.]/g, '-')}.png`, { type: blob.type })];

        await navigator.share({
          title: 'Check my automated trades!',
          text: getShareText(),
          files: filesArray,
          url: 'https://www.gunbot.com/'
        });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      console.error('Web Share API not supported in this browser');
    }
  };

  const copyToClipboard = async () => {
    if (screenshotUrl) {
      try {
        const response = await fetch(screenshotUrl);
        const blob = await response.blob();
        console.log('Blob fetched:', blob);
        await copyBlobToClipboard(blob);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    }
  };

  return (
    <ScreenshotContext.Provider value={{ componentRef, handleScreenshot, screenshotLoading, screenshotClasses }}>
      {children}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={screenshotClasses.dialogContent}>
          {screenshotLoading ? (
            <CircularProgress className={screenshotClasses.rotating} />
          ) : (
            <img src={screenshotUrl} alt="Screenshot" className={screenshotClasses.screenshotImage} />
          )}
        </DialogContent>
        <DialogActions className={screenshotClasses.dialogActions}>
          <IconButton onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
          {webShareSupported && (
            <IconButton onClick={shareUsingWebShareAPI} color="primary">
              <ShareIcon />
            </IconButton>
          )}
          {(webShareSupported || !isFirefox) && (
            <IconButton onClick={copyToClipboard} color="primary">
              <CopyIcon />
            </IconButton>
          )}
          <IconButton onClick={handleDownload} color="primary">
            <DownloadIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </ScreenshotContext.Provider>
  );
};
